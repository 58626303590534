import {
  type LoaderFunctionArgs,
  type MetaFunction,
  matchRoutes,
  redirect,
} from "react-router";
import { ClientOnly } from "remix-utils/client-only";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { paths } from "~/paths";
import { App } from "../../src/AppClient";

const frontendRoutes = [
  paths.signIn,
  paths.authCallback,
  paths.experts.profile,
  paths.search,
  paths.usageAgreement,
  paths.userTypeSelection,
  paths.onboarding.client,
  paths.onboarding.expert,
  paths.profile.documents.edit,
  `${paths.profile.root}/*`,
  `${paths.account.root}/*`,
  paths.notifications,
].map((r) => ({ path: r }));

export function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const matches = matchRoutes(frontendRoutes, url.pathname);
  if (!matches?.length) {
    throw redirect(paths.notFound);
  }
}

export default function Component() {
  return (
    <ClientOnly fallback={<Skeleton repeat={5} />}>{() => <App />}</ClientOnly>
  );
}

export const meta: MetaFunction = () => {
  const title = "GuideStack";

  return [
    {
      title,
    },
    {
      property: "og:title",
      content: title,
    },
  ];
};
